import React from "react";
import HammerImg from "../assets/img/hammer.png";
import { css } from "emotion";
import { Link } from "gatsby";

import "../assets/css/index.css";
import "../assets/css/bootstrap.min.css";

export const NotFoundStyle = () => css`
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    font-size: 26px;
    padding: 30vh 20px 20px 20px;
  }

  .logo {
    max-width: 150px;
  }

  p {
    font-family: sans-serif;
    text-align: center;
  }

  .message {
    font-weight: 600;
    font-size: 40px;
    margin-top: 40px;
    text-transform: uppercase;
  }

  .go-back {
    font-size: 20px;
  }
`;

const NotFound = () => {
  return (
    <div className={NotFoundStyle()}>
      <div className="content">
        <img className="logo" src={HammerImg} alt="hammer.png" />
        <p className="message">Page Not Found</p>
        <Link className="go-back" to="/">
          GO BACK TO HOME PAGE
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
